
export const characters = [  
    { id: 1, profile: 'profile1' ,Bounce:4, Spin:3, Size:2, Power:3,  Speed:4},//初級白貓
    { id: 2, profile: 'profile2' ,Bounce:5, Spin:5, Size:1, Power:1,  Speed:5},//初級黑貓
    { id: 3, profile: 'profile3' ,Bounce:1, Spin:1, Size:5, Power:5,  Speed:1},//中級白貓
  ]; 
  
  export const Properties = {
    profile1: { restitution: 0.95, density: 5,  radius: 30,  frictionAir: 0.005 ,   mass: 10},
    profile2: { restitution: 0.97, density: 1, radius: 15,  frictionAir: 0.002,  mass: 1},
    profile3: { restitution: 0.75, density: 50,   radius: 50,  frictionAir: 0.01 ,   mass: 5000 },//fat cat
  };

  export const skin = [  
    { id: 101, character: 1,name:'Catz', price:1, dayUrl: '/asset/Skin/Icon_mw_cat.png'        ,nightUrl: '/asset/Skin/Icon_mb_cat.png'        ,effectPreset:'default', unlockMethod:'mission'},//初級白貓
    { id: 102, character: 1,name:'Seaslashz', price:1, dayUrl: '/asset/Skin/Icon_mw_piratecat.png'  ,nightUrl: '/asset/Skin/Icon_mb_piratecat.png'  ,effectPreset:'pirate_m', unlockMethod:'purchase'},//初級黑貓
    { id: 103, character: 1,name:'Nightpawz', price:1, dayUrl: '/asset/Skin/Icon_mw_ninjacat.png'   ,nightUrl: '/asset/Skin/Icon_mb_ninjacat.png'  ,effectPreset:'ninja_m', unlockMethod:'purchase'},//中級白貓
    { id: 104, character: 1,name:'Neonclawz', price:1, dayUrl: '/asset/Skin/Icon_mw_robotcat.png'   ,nightUrl: '/asset/Skin/Icon_mb_robotcat.png'    ,effectPreset:'robot_m', unlockMethod:'purchase'},//中級白貓
 
    { id: 201, character: 2,name:'Tailz', price:1, dayUrl: '/asset/Skin/Icon_sw_cat.png'        ,nightUrl: '/asset/Skin/Icon_sb_cat.png'        ,effectPreset:'default', unlockMethod:'mission'},//中級白貓
    { id: 202, character: 2,name:'Swiftfinz', price:1, dayUrl: '/asset/Skin/Icon_sw_piratecat.png'  ,nightUrl: '/asset/Skin/Icon_sb_piratecat.png'  ,effectPreset:'pirate_s', unlockMethod:'purchase'},//中級白貓
    { id: 203, character: 2,name:'Whisperclawz', price:1, dayUrl: '/asset/Skin/Icon_sw_ninjacat.png'   ,nightUrl: '/asset/Skin/Icon_sb_ninjacat.png'   ,effectPreset:'ninja_s', unlockMethod:'purchase'},//中級白貓
    { id: 204, character: 2,name:'Bytepawz', price:1, dayUrl: '/asset/Skin/Icon_sw_robotcat.png'   ,nightUrl: '/asset/Skin/Icon_sb_robotcat.png'     ,effectPreset:'robot_s', unlockMethod:'purchase'},//中級白貓

    { id: 301, character: 3,name:'Kingz', price:1, dayUrl: '/asset/Skin/Icon_lw_cat.png'        ,nightUrl: '/asset/Skin/Icon_lb_cat.png'        ,effectPreset:'default', unlockMethod:'mission'},//中級白貓
    { id: 302, character: 3,name:'Stormsailz', price:1, dayUrl: '/asset/Skin/Icon_lw_piratecat.png'  ,nightUrl: '/asset/Skin/Icon_lb_piratecat.png'  ,effectPreset:'pirate_l', unlockMethod:'purchase'},//中級白貓
    { id: 303, character: 3,name:'Ghostbladez', price:1, dayUrl: '/asset/Skin/Icon_lw_ninjacat.png'   ,nightUrl: '/asset/Skin/Icon_lb_ninjacat.png'   ,effectPreset:'ninja_l', unlockMethod:'purchase'},//中級白貓
    { id: 304, character: 3,name:'Steelcorez', price:1, dayUrl: '/asset/Skin/Icon_lw_robotcat.png'   ,nightUrl: '/asset/Skin/Icon_lb_robotcat.png'    ,effectPreset:'robot_l', unlockMethod:'purchase'},//中級白貓
  ];

  export const effectPresets = {
    default: {
      sparkColor: `hsla(0, 50%, 50%, 1)`, 
      hueDir: -1,
      addsparkShape: false,
      sparkShape: '/asset/effect/default.webp',
      traceColorGradient: (ratio) => `rgb(${Math.floor(255 * (1-ratio))}, ${Math.floor(255 * (ratio))}, ${Math.floor(255 * (ratio))})`, // 黃到紅的漸變
      initialRadius: 5,
      shrinkRate: 0.85,
      addShape: false,
      traceShape: '/asset/effect/default.webp',//星星
      traceShapescale: 0.2,
      traceShapeColor:'hsla(60, 100%, 50%, 1)'
    },

    pirate_m: {
      sparkColor: 'hsla(300, 50%, 50%, 1)', 
      hueDir: -1,
      addsparkShape: true,
      sparkShape: '/asset/effect/e_spark_pirate_m.webp',
      traceColorGradient: (ratio) => `rgb(${Math.floor(255 * (ratio))}, ${Math.floor(200 * (ratio))}, 150)`, // 黃到紅的漸變
      initialRadius: 5,
      shrinkRate: 0.95,
      addShape: true,
      traceShape: '/asset/effect/e_tracer_pirate_m.webp',//星星
      traceShapeRotate: false,
      traceShapescale: 0.5,
      traceShapeColor:'hsla(60, 100%, 50%, 1)'
    },
    ninja_m: {
      sparkColor: 'hsla(206, 100%, 80%, 1)',
      hueDir: 1,
      addsparkShape: true,
      sparkShape: '/asset/effect/e_spark_ninja_m.webp',
      traceColorGradient: (ratio) => `rgb(${Math.floor(255 * (ratio))}, ${Math.floor(255 * (1-ratio))}, ${Math.floor(255 * (ratio))})`,
      initialRadius: 10,
      shrinkRate: 0.95,
      addShape: true,
      traceShape: '/asset/effect/e_tracer_ninja_m.webp',
      traceShapescale: 0.5,
      traceShapeRotate: true,
      traceShapeColor: 'hsla(217, 16%, 66%, 0.5)'
  },
  robot_m: {
      sparkColor: 'hsla(200, 100%, 95%, 1)',
      hueDir: 1,
      addsparkShape: true,
      sparkShape: '/asset/effect/e_spark_robot_m.webp',
      traceColorGradient: (ratio) => `rgb(${Math.floor(255 * (1-ratio))}, ${Math.floor(255 * (1-ratio))}, 255)`,
      initialRadius: 8,
      shrinkRate: 0.95,
      addShape: true,
      traceShape: '/asset/effect/e_tracer_robot_m.webp',
      traceShapescale: 0.5,
      traceShapeRotate: false,
      traceShapeColor: 'hsla(240, 100%, 50%, 1)'
  },
  
  pirate_s: {
    sparkColor: 'hsla(300, 50%, 50%, 1)', 
    hueDir: -1,
    addsparkShape: true,
    sparkShape: '/asset/effect/e_spark_pirate_s.webp',
    traceColorGradient: (ratio) => `rgb(${Math.floor(255 * (ratio))}, ${Math.floor(255 * (ratio))}, 150)`, // 黃到紅的漸變
    initialRadius: 5,
    shrinkRate: 0.95,
    addShape: true,
    traceShape: '/asset/effect/e_tracer_pirate_s.webp',//星星
    traceShapescale: 0.25,
    traceShapeRotate: false,
    traceShapeColor:'hsla(60, 100%, 50%, 1)'
  },
  ninja_s: {
    sparkColor: 'hsla(206, 100%, 80%, 1)',
    hueDir: 1,
    addsparkShape: true,
    sparkShape: '/asset/effect/e_spark_ninja_s.webp',
    traceColorGradient: (ratio) => `rgb(${Math.floor(255 * (ratio))}, ${Math.floor(255 * (1-ratio))}, ${Math.floor(255 * (ratio))})`,
    initialRadius: 10,
    shrinkRate: 0.95,
    addShape: true,
    traceShape: '/asset/effect/e_tracer_ninja_s.webp',
    traceShapescale: 0.25,
    traceShapeColor: 'hsla(217, 16%, 66%, 0.5)'
},
robot_s: {
    sparkColor: 'hsla(240, 50%, 50%, 1)',
    hueDir: 1,
    addsparkShape: true,
    sparkShape: '/asset/effect/e_spark_robot_s.webp',
    traceColorGradient: (ratio) => `rgb(${Math.floor(255 * (1-ratio))}, ${Math.floor(0 * (ratio))}, ${Math.floor(255 * (1-ratio))})`,
    initialRadius: 8,
    shrinkRate: 0.9,
    addShape: true,
    traceShape: '/asset/effect/e_tracer_robot_s.webp',
    traceShapescale: 0.25,
    traceShapeColor: 'hsla(240, 100%, 50%, 1)'
},

pirate_l: {
  sparkColor: 'hsla(300, 50%, 50%, 1)', 
  hueDir: -1,
  addsparkShape: true,
  sparkShape: '/asset/effect/e_spark_pirate_l.webp',
  traceColorGradient: (ratio) => `rgb(${Math.floor(255 * (ratio))}, ${Math.floor(200 * (ratio))}, 0)`, // 黃到紅的漸變
  initialRadius: 5,
  shrinkRate: 0.95,
  addShape: true,
  traceShape: '/asset/effect/e_tracer_pirate_l.webp',//星星
  traceShapescale: 0.7,
  traceShapeColor:'hsla(60, 100%, 50%, 1)'
},
ninja_l: {
  sparkColor: 'hsla(206, 100%, 80%, 0.8)',
  hueDir: 1,
  addsparkShape: true,
  sparkShape: '/asset/effect/e_spark_ninja_l.webp',
  traceColorGradient: (ratio) => `rgb(${Math.floor(255 * (ratio))}, ${Math.floor(255 * (1-ratio))}, ${Math.floor(255 * (ratio))})`,
  initialRadius: 10,
  shrinkRate: 0.95,
  addShape: true,
  traceShape: '/asset/effect/e_tracer_ninja_l.webp',
  traceShapescale: 0.7,
  traceShapeColor: 'hsla(217, 16%, 66%, 0.5)'
},
robot_l: {
  sparkColor: 'hsla(240, 50%, 50%, 0.5)',
  hueDir: 1,
  addsparkShape: true,
  sparkShape: '/asset/effect/e_spark_robot_l.webp',
  traceColorGradient: (ratio) => `rgb(${Math.floor(255 * (ratio))}, ${Math.floor(255 * (1-ratio))}, 255)`,
  initialRadius: 8,
  shrinkRate: 0.95,
  addShape: true,
  traceShape: '/asset/effect/e_tracer_robot_l.webp',
  traceShapescale: 0.7,
  traceShapeColor: 'hsla(240, 100%, 50%, 1)'
},
  };
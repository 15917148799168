import React, { useState, useEffect, useRef, useCallback } from 'react';
import { db,firebase } from './firebase';
import './Main.css';
import MatterCanvas from './components/MatterCanvas';
import ScoreBoard from './components/ScoreBoard';
import Controls from './components/Controls';
import useMatterJS from './hooks/useMatterJS';

function Main({ user, selectedCharacter, userColorScheme }) {
  const [points, setPoints] = useState(0);
  const [combos, setCombos] = useState(0);
  const [maxCombos, setMaxCombos] = useState(0);
  const [newMaxCombos, setNewMaxCombos] = useState(0);
  const [todayGamePoints, setTodayGamePoints] = useState(0);
  const [pendingGamePoints, setPendingGamePoints] = useState(0);
  const [maxPendingPoints, setMaxPendingPoints] = useState(500);
  const maxPendingPointsRef = useRef(500);
  const [selectedSkin, setSelectedSkin] = useState(101);
  const [todayGamePointsReached, setTodayGamePointsReached] = useState(false);
  const [allFriends, setAllFriends] = useState([]);
  const [timeLeft, setTimeLeft] = useState({ hours: '00', minutes: '00', seconds: '00' });
  const [isSoundOn, setIsSoundOn] = useState(true);
  const isSoundOnRef = useRef(true);
  const containerRef = useRef(null);
  const scene = useRef(null);
  const selectedCharacterRef = useRef(selectedCharacter);
  const [pendingPointsSettle, setPendingPointsSettle] = useState(false)
  

  useEffect(() => {
    maxPendingPointsRef.current = maxPendingPoints;
  }, [maxPendingPoints]);

  const calculateTimeLeft = useCallback(() => {
    const endTime = new Date().setHours(24, 0, 0, 0);
    const now = Date.now();
    const timeDiff = endTime - now;
    if (timeDiff <= 0) {
      return { hours: '00', minutes: '00', seconds: '00' };
    }
    const hours = String(Math.floor((timeDiff / (1000 * 60 * 60)) % 24)).padStart(2, '0');
    const minutes = String(Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
    const seconds = String(Math.floor((timeDiff % (1000 * 60)) / 1000)).padStart(2, '0');
    return { hours, minutes, seconds };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(interval); // 清理 interval
  }, [calculateTimeLeft]);

  useEffect(() => {
    const fetchUserData = async () => {
      let userId = '2200344733'
      if(window.Telegram.WebApp.initDataUnsafe.user)
      {
        userId = window.Telegram.WebApp.initDataUnsafe.user.id.toString();
      }
      const userRef = db.collection('users').doc(userId);
      console.log("Try access firebase(Read)")
      const doc = await userRef.get();
      if (doc.exists) {
        const userData = doc.data();
        setPoints(userData.points || 0);
        setMaxCombos(userData.maxCombos || 0);
        setTodayGamePoints(userData.todayGamePoints || 0);
        setMaxPendingPoints(500 - userData.todayGamePoints || 0);
        selectedCharacterRef.current = userData.selectedCharacter;
        setSelectedSkin(userData.skin);

        if (userData.todayGamePoints >= 500) {
          setTodayGamePointsReached(true);
        }

        if (userData.selected_friends) {
          const friendsData = await Promise.all(userData.selected_friends.map(async (friendId) => {
            const friendRef = db.collection('users').doc(friendId);
            const friendDoc = await friendRef.get();
            if (friendDoc.exists) {
              const friendData = friendDoc.data();
              return {
                id: friendId,
                first_name: friendData.first_name,
                last_name: friendData.last_name,
                selectedCharacter: friendData.selectedCharacter,
                skin: friendData.skin,
                dayNight: friendData.dayNight,
              };
            } else {
              return null;
            }
          }));
          setAllFriends(friendsData);
        }
      }
    };

    fetchUserData();
    // 添加依赖项以避免不必要的调用
  }, []);

 

  useMatterJS(allFriends, selectedCharacterRef.current, selectedSkin, scene, combos, setCombos, setPendingGamePoints, isSoundOnRef, user, maxPendingPointsRef, userColorScheme);

  useEffect(() => {
    if (pendingGamePoints > 0 && todayGamePoints < 500) {
      const timer = setTimeout(async () => {
        const userId = window.Telegram.WebApp.initDataUnsafe.user.id.toString();
        const userRef = db.collection('users').doc(userId);
        console.log("Try access firebase(Read)")
        const doc = await userRef.get();
        if (doc.exists) {
          const userData = doc.data();
          let newTodayGamePoints = userData.todayGamePoints + pendingGamePoints;
          if (newTodayGamePoints > 500) {
            newTodayGamePoints = 500;
          }
          await userRef.update({
            todayGamePoints: newTodayGamePoints,
            gamePoints : firebase.firestore.FieldValue.increment(pendingGamePoints),
            points: userData.points + pendingGamePoints
          });
          setTodayGamePoints(newTodayGamePoints);
          setMaxPendingPoints(500 - newTodayGamePoints);
          setPoints(points + pendingGamePoints);
          setPendingPointsSettle(true);
          setTimeout(() => {
            setPendingPointsSettle(false);
          }, 3000);
          setPendingGamePoints(0);
          if (newTodayGamePoints >= 500) {
            setTodayGamePointsReached(true);
          }
        }
      }, 3000);

      return () => clearTimeout(timer); // 清理 timeout
    }
    // 添加依赖项以避免不必要的调用
  }, [pendingGamePoints, todayGamePoints, points]);

  useEffect(() => {
    if (combos > maxCombos) {
      setNewMaxCombos(combos);
    }
  }, [combos, maxCombos]);

  useEffect(() => {
    if (newMaxCombos > maxCombos) {
      const timer = setTimeout(async () => {
        const userId = window.Telegram.WebApp.initDataUnsafe.user.id.toString();
        const userRef = db.collection('users').doc(userId);
        console.log("Try access firebase(Read)")
        const doc = await userRef.get();
        if (doc.exists) {
          const userData = doc.data();
          if (userData.maxCombos < newMaxCombos) {
            await userRef.update({ maxCombos: newMaxCombos });
            setMaxCombos(newMaxCombos);
          }
        }
      }, 3000);

      return () => clearTimeout(timer); // 清理 timeout
    }
    // 添加依赖项以避免不必要的调用
  }, [maxCombos, newMaxCombos]);

  // 使用 useCallback 缓存函数
  const toggleSound = useCallback(() => {
    setIsSoundOn(prevState => {
      isSoundOnRef.current = !prevState;
      return !prevState;
    });
  }, []);

  return (
    <div ref={containerRef} className={`main-container`}>
      <Controls toggleSound={toggleSound} isSoundOn={isSoundOn} />
      <MatterCanvas scene={scene} />
      <ScoreBoard
        combos={combos}
        maxCombos={maxCombos}
        todayGamePoints={todayGamePoints}
        todayGamePointsReached={todayGamePointsReached}
        points={points}
        pendingGamePoints={pendingGamePoints}
        timeLeft={timeLeft}
        pendingPointsSettle = {pendingPointsSettle}
        userColorScheme = {userColorScheme}
      />
    </div>
  );
}

export default Main;

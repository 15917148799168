import { db } from '../firebase';

const FetchLeaderboards = async () => {
  try {
    const [pointsDoc, combosDoc] = await Promise.all([
      db.collection('leaderboards').doc('points').get(),
      db.collection('leaderboards').doc('maxCombos').get()
    ]);

    const pointsLeaderboard = pointsDoc.exists ? (pointsDoc.data().leaderboard || []).slice(0, 999) : [];
    const maxCombosLeaderboard = combosDoc.exists ? (combosDoc.data().leaderboard || []).slice(0, 999) : [];
    
    // 确保 updatedAt 是一个 Date 对象
    const lastUpdate = pointsDoc.exists && pointsDoc.data().updatedAt 
      ? pointsDoc.data().updatedAt.toDate() 
      : new Date();

    return { pointsLeaderboard, maxCombosLeaderboard, lastUpdate };
  } catch (error) {
    console.error('Error fetching leaderboards:', error);
    return { pointsLeaderboard: [], maxCombosLeaderboard: [], lastUpdate: new Date() };
  }
};

export default FetchLeaderboards;
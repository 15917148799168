// Leaderboard.js
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { db } from './firebase';
import './Leaderboard.css';
import useSound from 'use-sound';
import Countdown from './components/Countdown';
import LeaderboardItem from './components/LeaderboardItem';

function Leaderboard({ pointsLeaderboard = [], maxCombosLeaderboard = [] }) {
  const [isMaxCombos, setIsMaxCombos] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [userPointRank, setUserPointRank] = useState(null);
  const [userComboRank, setUserComboRank] = useState(null);
  const leaderboardContainerRef = useRef(null);
  const light = window.Telegram.WebApp.colorScheme === 'light';

  const currentLeaderboard = useMemo(() => {
    return isMaxCombos ? maxCombosLeaderboard : pointsLeaderboard;
  }, [isMaxCombos, maxCombosLeaderboard, pointsLeaderboard]);

  useEffect(() => {
    const initUser = async () => {
      const user = window.Telegram.WebApp.initDataUnsafe.user;
      const userId = user.id.toString();
      if (user) {
        const userScore = await getCurrentUserScore(userId);
        setCurrentUser({
          id: userId,
          username: user.username,
          points: userScore.points || 0,
          maxCombos: userScore.maxCombos || 0,
          dayNight: userScore.dayNight || 0,
          skin: userScore.skin || 'defaultSkin',
        });

        const rankInPointLeaderboard = pointsLeaderboard.findIndex(
          (u) => u.userId === userId
        );
        setUserPointRank(rankInPointLeaderboard !== -1 ? rankInPointLeaderboard + 1 : 9999);

        const rankInComboLeaderboard = maxCombosLeaderboard.findIndex(
          (u) => u.userId === userId
        );
        setUserComboRank(rankInComboLeaderboard !== -1 ? rankInComboLeaderboard + 1 : 9999);
      }
    };
    initUser();
  }, [pointsLeaderboard, maxCombosLeaderboard]);

  const endTime = Date.UTC(2024, 11, 31, 23, 59, 0, 0);

  const getCurrentUserScore = async (idNumber) => {
    const userRef = db.collection('users').doc(idNumber);
    const userDoc = await userRef.get();
    return userDoc.exists ? userDoc.data() : {};
  };

  const [playClickSound] = useSound(`${process.env.PUBLIC_URL}/asset/click.mp3`, { volume: 0.5 });

  const handleButtonClick = (newIsMaxCombos) => {
    if (newIsMaxCombos !== isMaxCombos) {
      setIsMaxCombos(newIsMaxCombos);
      playClickSound();
      if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
      }
    }
  };

  return (
    <>
      <Countdown endTime={endTime} />
      <div className="leaderboard-container" ref={leaderboardContainerRef}>
        <div className="leaderboard-background Upper">
          <img
            src={`${process.env.PUBLIC_URL}/asset/Background/Leaderboard_BG_1_${
              light ? 'day' : 'night'
            }.webp`}
            alt="Background"
            style={{ width: '100%' }}
          />
        </div>
        <div className="leaderboard-background Lower">
          <img
            src={`${process.env.PUBLIC_URL}/asset/Background/Leaderboard_BG_2_${
              light ? 'day' : 'night'
            }.webp`}
            alt="Background"
            style={{ width: '100%' }}
          />
        </div>
        <div className="leaderboard-header">
          <div className={`leaderboard-Tab ${light ? 'day' : 'night'}`}>
            <button
              onClick={() => handleButtonClick(false)}
              className={!isMaxCombos ? 'active-tab' : ''}
            >
              POINT
            </button>
            <button
              onClick={() => handleButtonClick(true)}
              className={isMaxCombos ? 'active-tab' : ''}
            >
              COMBO
            </button>
          </div>
        </div>
        <div className="leaderboard-list-container">
          <ul className="leaderboard-list">
            {pointsLeaderboard.length > 0 && (
              <div className="leaderboard-list-Tier-container champion-tier">
                <img
                  src={`${process.env.PUBLIC_URL}/asset/CHAMPION.webp`}
                  alt="CHAMPION TIER"
                  className="Tier-Header"
                />
                <div className="leaderboard-item-container">
                  {currentLeaderboard.slice(0, 3).map((user, index) => (
                    <LeaderboardItem
                      key={user.userId}
                      user={user}
                      index={index}
                      isHighlighted={user.userId === currentUser?.id}
                      isMaxCombos={isMaxCombos}
                    />
                  ))}
                </div>
              </div>
            )}
            {pointsLeaderboard.length > 3 && (
              <div className="leaderboard-list-Tier-container conqueror-tier">
                <img
                  src={`${process.env.PUBLIC_URL}/asset/CONQUEROR.webp`}
                  alt="CONQUEROR TIER"
                  className="Tier-Header"
                />
                <div className="leaderboard-item-container">
                  {currentLeaderboard.slice(3, 10).map((user, index) => (
                    <LeaderboardItem
                      key={user.userId}
                      user={user}
                      index={3 + index}
                      isHighlighted={user.userId === currentUser?.id}
                      isMaxCombos={isMaxCombos}
                    />
                  ))}
                </div>
              </div>
            )}
            {pointsLeaderboard.length > 10 && (
              <div className="leaderboard-list-Tier-container challenger-tier">
                <img
                  src={`${process.env.PUBLIC_URL}/asset/CHALLERNGER.webp`}
                  alt="CHALLENGER TIER"
                  className="Tier-Header"
                />
                <div className="leaderboard-item-container">
                  {currentLeaderboard.slice(10, 100).map((user, index) => (
                    <LeaderboardItem
                      key={user.userId}
                      user={user}
                      index={10 + index}
                      isHighlighted={user.userId === currentUser?.id}
                      isMaxCombos={isMaxCombos}
                    />
                  ))}
                </div>
              </div>
            )}
            {currentUser && (
              <>
                <li className="leaderboard-item spacer">...</li>
                <LeaderboardItem
                  user={{ ...currentUser }}
                  index={(isMaxCombos ? userComboRank : userPointRank) - 1}
                  isHighlighted={true}
                  isMaxCombos={isMaxCombos}
                />
              </>
            )}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Leaderboard;

// LeaderboardItem.js
import React from 'react';
import { skin } from '../CharacterDatabase';

const skinMap = {};
skin.forEach(s => { skinMap[s.id] = s; });

function LeaderboardItem({ user, index, isHighlighted, isMaxCombos}) {
  let highlightClass = '';
  if (index < 3) {
    highlightClass = `highlight-${index + 1}`;
  } else if (index>100) {
    highlightClass = 'highlight-outOfRank';
  }else if (isHighlighted) {
    highlightClass = 'highlight';
  } else if (index < 10) {
    highlightClass = 'Tire2';
  }

  const userSkin = skinMap[user.skin] || {};
  const avatarSrc = user.dayNight === 'day' ? userSkin.dayUrl : userSkin.nightUrl || '/asset/Icon_whitecat.png';

  return (
    <li key={user.id} className={`leaderboard-item ${highlightClass ? highlightClass : ''}`}>
      <div className="rank-container">
        <span className="rank">{index > 999 ? '999+' : index + 1}</span>
      </div>
      <div className='information'>
        <span className="username">{user.username || 'Anonymous'}</span>
        <br />
        <span className={`points`}>
          {isMaxCombos ? user.maxCombos || 0 : user.points || 0}
          {isMaxCombos ? ' Combos' : ' CATS '}
        </span>
      </div>
      <img
        src={avatarSrc}
        alt="User Icon"
        className="user-avatar"
      />
    </li>
  );
}

export default React.memo(LeaderboardItem);
